<template>
  <registration-success
    v-if="success"
    :current-context="context"
  />
  <!--
    use v-show and not v-if,
    so form values are not lost when step change
  -->
  <StepsWrapper
    v-if="context && !success"
    :current-step="step"
    :steps="context.steps"
    class="-mt-5 mb-6"
  >
    <StepComponent
      v-for="stepNumber in context.steps"
      :key="stepNumber"
      class="border-gray-100"
      :class="stepNumber <= step ? 'border-theme-500' : 'border-gray-400'"
    >
      <p
        class="text-center text-sm"
      >
        {{ stepNumber }}
      </p>
    </StepComponent>
  </StepsWrapper>

  <StepsWrapper
    v-else-if="!success"
    class="flex h-12 w-full justify-between -mt-5 mb-6"
    :current-step="step"
    :steps="step"
  >
    <StepComponent
      class="bg-red-100 border-gray-100/20"
    >
      <p>
        1
      </p>
    </StepComponent>

    <StepComponent
      class="bg-red-100 border-gray-100/20"
    >
      <p>
        ?
      </p>
    </StepComponent>
  </StepsWrapper>

  <form
    v-if="!success"
    novalidate
    @submit.prevent="onSubmit"
  >
    <form-global-errors
      class="mb-5"
    />

    <registration-step-context
      v-show="step === 1"
      :current-step="step"
      :current-context="context"
      @next="next"
      @set-context="setContext"
    />

    <registration-step-manager
      v-show="step === 2"
      :current-step="step"
      :current-context="context"
      @next="next"
      @back="back"
    />

    <registration-step-employee
      v-show="step === 3"
      :current-step="step"
      :current-context="context"
      @next="next"
      @back="back"
    />

    <registration-step-guest
      v-show="step === 4"
      :current-step="step"
      :current-context="context"
      @next="next"
      @back="back"
    />

    <registration-step-verifications
      v-show="step === 5"
      :current-step="step"
      @back="back"
    >
      <app-button
        type="submit"
        display-classes="flex"
        feature="confirm"
        :disabled="formSubmitting || invalid || !isFormFilled"
        class="py-3 px-6 min-w-fit max-w-8/10 mt-8 gap-2"
        :loading="formSubmitting"
        :hide-icon="false"
        :label="t('auth.extranet_register.form.validate_registration')"
      />
    </registration-step-verifications>
  </form>

  <p
    class="font-bold block mx-auto w-fit mt-8"
  >
    {{ t('auth.messages.have_account') }}

    <router-link
      :to="{ name: 'login' }"
      class="app-link"
    >
      {{ t('auth.messages.login') }}
    </router-link>
  </p>
</template>

<script setup>
import {
  ref,
  computed,
  provide,
} from 'vue'
import { useI18n } from 'vue-i18n'

import { register } from '@shared/http/api'
import useLocale from '@shared/hooks/locale'
import useForm from '@shared/hooks/form/form'
import AppButton from '@shared/components/ui/AppButton.vue'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'
import RegistrationStepContext from '@extranet/components/auth/registration/RegistrationStepContext.vue'
import RegistrationStepEmployee from '@extranet/components/auth/registration/RegistrationStepEmployee.vue'
import RegistrationStepGuest from '@extranet/components/auth/registration/RegistrationStepGuest.vue'
import RegistrationStepManager from '@extranet/components/auth/registration/RegistrationStepManager.vue'
import RegistrationStepVerifications from '@extranet/components/auth/registration/RegistrationStepVerifications.vue'
import RegistrationSuccess from '@extranet/components/auth/registration/RegistrationSuccess.vue'
import StepComponent from '@extranet/components/auth/registration/Steps/StepComponent.vue'
import StepsWrapper from '@extranet/components/auth/registration/Steps/StepsWrapper.vue'

const { t } = useI18n()
const { shortLocale } = useLocale()

// ========== FORM ==========

const formSubmitting = ref(false)
const formErrors = ref({})
const step = ref(1)
const success = ref(false)
const context = ref(null)
const recipientEmails = ref([])

const {
  handleSubmit,
  invalid,
  form,
} = useForm({ additionalErrors: formErrors })

const isFormFilled = computed(() => (
  form.values.terms_conditions
    && form.values.terms_conditions
))

const setContext = (value) => {
  context.value = value
}

async function onSubmit() {
  const fieldsToSubmit = await handleSubmit()

  if (fieldsToSubmit && !formSubmitting.value) {
    formSubmitting.value = true

    // Set the submitted locale
    fieldsToSubmit.locale = shortLocale.value

    const params = {
      data: {
        type: 'users',
        attributes: {
          ...fieldsToSubmit,
          ...{
            context: context.value?.name,
          },
        },
      },
    }

    register(params)
      .then(() => {
        recipientEmails.value = [
          form.values.email,
          form.values?.employee?.email,
        ]

        success.value = true
      })
      .catch((e) => {
        if (e.response?.data?.errors) {
          formErrors.value = e.response.data.errors
        }
      })
      .finally(() => {
        formSubmitting.value = false
      })
  }
}

// ========== HANDLERS ==========
// Validate context step

function back() {
  if (step.value === 5) {
    if (context.value.has_guest_account) {
      step.value = 4
    } else if (context.value.has_employee_account) {
      step.value = 3
    } else {
      step.value = 2
    }
  } else if (step.value === 4) {
    if (context.value.has_employee_account) {
      step.value = 3
    } else {
      step.value = 2
    }
  } else {
    step.value -= 1
  }
}

function next() {
  if (step.value === 2) {
    if (context.value.has_employee_account) {
      step.value = 3
    } else if (context.value.has_guest_account) {
      step.value = 4
    } else {
      step.value = 5
    }
  } else if (step.value === 3) {
    if (context.value.has_guest_account) {
      step.value = 4
    } else {
      step.value = 5
    }
  } else {
    step.value += 1
  }
}

provide('recipientEmails', recipientEmails)
</script>
