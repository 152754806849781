<template>
  <div>
    <p
      class="text-center font-bold mt-6"
    >
      {{ t('auth.extranet_register.form.last_step_title') }} : {{ t('form.titles.verifications') }}
    </p>

    <form-group
      rules="required"
      name="privacy_policy"
      type="checkbox"
      flex-direction="row"
      margins="mb-0 mt-8"
    >
      <template #label>
        <label class="ml-2 font-medium form-label">
          {{ t('form.labels.register.privacy_policy.part1') }}
          <span
            class="app-link"
            @click="togglePrivacyPolicy"
          >
            {{ t('form.labels.register.privacy_policy.part2') }}
          </span>
        </label>
      </template>
    </form-group>

    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="displayPrivacyPolicy"
      class="text-justify h-60 overflow-y-scroll"
      v-html="privacyPolicyl10nContent"
    />

    <form-group
      rules="required"
      name="terms_conditions"
      type="checkbox"
      flex-direction="row"
      margins="mb-0"
    >
      <template #label>
        <label class="ml-2 font-medium form-label">
          {{ t('form.labels.register.terms_conditions.part1') }}
          <span
            class="app-link"
            @click="toggleTermsConditions"
          >
            {{ t('form.labels.register.terms_conditions.part2') }}
          </span>
        </label>
      </template>
    </form-group>

    <div
      v-if="displayTermsCondition"
      class="text-justify h-60 overflow-y-scroll"
      v-html="termsConditionl10nContent"
    />

    <div
      class="flex justify-between"
    >
      <app-button
        type="button"
        :disabled="false"
        display-classes="flex"
        feature="back"
        class="py-3 px-6 min-w-fit max-w-8/10 mt-8 gap-2"
        :label="t('common.actions.return')"
        :hide-icon="false"
        @click="back"
      />

      <slot />
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'

import usePrivacyPolicy from '@shared/hooks/privacyPolicy'
import useTermsCondition from '@shared/hooks/termsCondition'

import AppButton from '@shared/components/ui/AppButton.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'

const { t } = useI18n()
const emits = defineEmits([
  'back',
])

defineProps({
  // JSON API resource used to
  // populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
})

// ---------- PRIVACY POLICY / TERMS CONDITIONS ----------

const displayTermsCondition = ref(false)
const displayPrivacyPolicy = ref(false)

const { l10nContent: termsConditionl10nContent } = useTermsCondition()
const { l10nContent: privacyPolicyl10nContent } = usePrivacyPolicy()

function toggleTermsConditions() {
  displayTermsCondition.value = !displayTermsCondition.value
}

function togglePrivacyPolicy() {
  displayPrivacyPolicy.value = !displayPrivacyPolicy.value
}

// ---------- STEPS ----------

function back() {
  emits('back')
}
</script>
