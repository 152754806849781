<template>
  <div
    class="lineBar relative w-full h-12 flex justify-between"
  >
    <slot />
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  // The current step
  currentStep: {
    type: Number,
    default: 0,
  },
  // Max steps count
  steps: {
    type: Number,
    default: 0,
  },
})

const sizeBar = computed(() => (100 / (props.steps - 1)))

const stepSize = computed(() => {
  if (props.currentStep === 1) {
    return '0%'
  }

  if (props.currentStep >= props.steps) {
    return '100%'
  }

  return `${(sizeBar.value * (props.currentStep - 1))}%`
})

</script>

<style lang="css" scoped>
.lineBar:before {
  content: '';
  position: absolute;
  top: 50%;
  height: 2px;
  width: 100%;
  transform: translateY(-50%);
  @apply bg-gray-500 rounded-full -z-1 ;
}

.lineBar:after {
  content: '';
  position: absolute;
  top: 50%;
  height: 2px;
  transform: translateY(-50%);
  z-index: 1;
  width: v-bind(stepSize);
  transition: width .25s ease;
  @apply bg-theme-500 rounded-full;
}
</style>
