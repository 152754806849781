<template>
  <login-form
    class="mb-2"
    :additional-errors="formErrors"
    :submitting="formSubmitting"
    with-password-forgotten
    with-credential-verification-not-received
    @submitted="handleSubmit"
  />

  <app-separator
    class="my-12"
  />

  <p
    class="font-bold block w-fit mx-auto mt-8"
  >
    {{ t('auth.messages.no_account') }}
  </p>

  <app-button
    feature="confirm"
    width="full"
    :label="t('auth.messages.register')"
    padding="py-3 px-5"
    display-classes="flex"
    class="min-w-fit max-w-8/10 mx-auto mt-8"
    @click="register"
  />
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import useLogin from '@shared/hooks/auth/login'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppSeparator from '@shared/components/ui/AppSeparator.vue'
import LoginForm from '@extranet/components/auth/login/LoginForm.vue'

const { t } = useI18n()

// ---------- FORM ----------

const {
  formSubmitting,
  formErrors,
  handleSubmit,
} = useLogin()

// ---------- ROUTES ----------

const router = useRouter()

function register() {
  router.push({ name: 'register' })
}
</script>
