<template>
  <div
    v-if="success"
  >
    <h2 class="font-bold mb-4 text-lg">
      {{ t('user_credential_verifications.verify.sent.title') }}
    </h2>

    <p class="mb-2">
      {{ t('user_credential_verifications.verify.sent.email_1', { email: credentialSent }) }}
    </p>

    <p class="mb-3">
      {{ t('user_credential_verifications.verify.sent.email_2') }}
    </p>

    <div class="mb-3">
      <app-button
        emphasis="low"
        :label="t('user_credential_verifications.verify.sent.again')"
        whitespace-classes="whitespace-normal"
        :loading="formSubmitting"
        :disabled="formSubmitting || !sendCooldownEnded"
        display-classes="flex"
        class="mx-auto"
        width="fit"
        @click="sendCredentialVerification(credentialSent)"
      />

      <p
        v-if="!sendCooldownEnded"
        class="text-xs text-center"
      >
        {{ t('user_credential_verifications.verify.sent.wait_before', { seconds: sendCooldown }) }}
      </p>
    </div>

    <app-button
      emphasis="low"
      :label="t('user_credential_verifications.verify.sent.correct')"
      whitespace-classes="whitespace-normal"
      display-classes="flex"
      class="mb-3 mx-auto"
      width="fit"
      @click="resetForm"
    />
  </div>

  <!--
    v-show is used to keep the form active,
    and do not lose the values
  -->
  <div
    v-show="!success"
  >
    <p class="text-xs mb-3">
      {{ t('auth.credential_verification.intro') }}
    </p>

    <!-- Verification form -->
    <form
      novalidate
      @submit.prevent="sendCredentialVerification(values.credential)"
    >
      <form-group
        rules="required|credential"
        name="credential"
        type="email"
        :placeholder="t('form.labels.user.credential')"
        :label="false"
        width="full"
        :initial-value="credential"
        :form-control-props="{
          iconProps: { library: 'coolicon', name: 'mail' },
        }"
      />

      <app-button
        type="submit"
        width="full"
        :disabled="formSubmitting"
        :loading="formSubmitting"
        feature="confirm"
        padding="py-3 px-5"
        display-classes="flex"
        class="min-w-fit max-w-8/10 mx-auto mt-8"
      />
    </form>
  </div>

  <router-link
    :to="{ name: 'login' }"
    class="app-link block w-fit mx-auto mt-8"
  >
    {{ t('auth.messages.login') }}
  </router-link>

  <router-link
    :to="{ name: 'register' }"
    class="app-link block w-fit mx-auto mt-3"
  >
    {{ t('auth.messages.register') }}
  </router-link>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useForm } from 'vee-validate'
import { useRoute } from 'vue-router'

import {
  sendCredentialVerification as apiSendCredentialVerification,
} from '@shared/http/api'
import AppButton from '@shared/components/ui/AppButton.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'

const { t } = useI18n()

const route = useRoute()
const credential = route.query.credential

const { values, setErrors } = useForm()
const formSubmitting = ref(false)
const credentialSent = ref() // Value of the credential sent for verification

const success = ref(false)

function sendCredentialVerification(value) {
  credentialSent.value = value
  formSubmitting.value = true

  // Create a new verification procedure
  apiSendCredentialVerification({
    data: {
      attributes: {
        credential: value,
      },
    },
  }).then(() => {
    success.value = true
    triggerSendCooldown()
  }).catch((e) => {
    if (e.response?.data?.errors) {
      setErrors({ ...e.response.data.errors })
    }
    resetForm()
  }).finally(() => {
    formSubmitting.value = false
  })
}

// Reset the initial form state
function resetForm() {
  success.value = false
  credentialSent.value = null
}

// ---------- SEND COOLDOWN ----------

const sendCooldown = ref(0)

function triggerSendCooldown() {
  sendCooldown.value = 60
}

const sendCooldownEnded = computed(() => (
  sendCooldown.value <= 0
))

setInterval(() => {
  if (sendCooldown.value > 0) {
    sendCooldown.value -= 1
  }
}, 1000)
</script>
