<template>
  <div>
    <p
      class="text-center font-bold mt-6"
    >
      {{ t('auth.extranet_register.form.step_title') }} {{ currentStep }} : {{ formTitle }}
    </p>

    <div
      class="mt-8"
    >
      <form-group
        :rules="`${requiredIfGuest}|email`"
        name="guest.email"
        type="email"
        :hint="hintsEmail"
        :form-control-props="{
          iconProps: { library: 'coolicon', name: 'mail' },
        }"
      />
    </div>

    <div
      class="flex justify-between"
    >
      <app-button
        type="button"
        feature="back"
        display-classes="flex"
        class="min-w-fit max-w-8/10 mt-8 py-3 px-5 gap-2"
        :label="t('common.actions.return')"
        :hide-icon="false"
        @click="back"
      />

      <app-button
        type="button"
        :disabled="!isFormFilled"
        display-classes="flex"
        feature="next"
        class="py-3 px-6 min-w-fit max-w-8/10 mt-8 flex-row-reverse gap-2"
        :label="t('common.actions.next')"
        :hide-icon="false"
        @click="next"
      />
    </div>
  </div>
</template>

<script setup>
import { capitalize } from 'lodash'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import useFormFields from '@shared/hooks/form/formFields'
import useTranslation from '@shared/hooks/translation'
import AppButton from '@shared/components/ui/AppButton.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'

const props = defineProps({
  // JSON API resource used to
  // populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Get the current step at the time.
  currentStep: {
    type: Number,
    required: true,
  },
  // Get current context
  currentContext: {
    type: Object,
    required: true,
  },
})

const emits = defineEmits([
  'next',
  'back',
])

const { enumAttribute } = useTranslation()
const { t } = useI18n()
const { form } = useFormFields(props)

const isFormFilled = computed(() => (
  form.value.values.guest?.email
))

// ---------- COMPUTED ----------

const requiredIfGuest = computed(() => (
  props.currentContext?.has_guest_account ? 'required' : ''
))

const formTitle = computed(() => (
  capitalize(`${t('auth.extranet_register.form.form_title')} ${t('enums.UserRoleEnum.GUEST_TRAVELLER')}`)
))

const hintsEmail = computed(() => (
  `${t('form.hints.auth.register.employee.not_duplicate_email')} ${
    t(`enums.UserRoleEnum.${enumAttribute(props.currentContext?.roles?.manager ?? 'DEFAULT')}`)} / ${
    t(`enums.UserRoleEnum.${enumAttribute(props.currentContext?.roles?.employee ?? 'DEFAULT')}`)}`
))

// ---------- STEPS ----------

function next() {
  emits('next')
}

function back() {
  emits('back')
}
</script>
