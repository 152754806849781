<template>
  <section class="flex">
    <!-- Main content -->
    <div class="flex items-center w-full lg:w-1/2 relative">
      <!-- Locale selector -->
      <app-locale-selector
        id="auth-locale-selector"
        class="w-36 absolute top-4 right-4 text-theme-500 font-bold"
        :options="staticLocalesOptionsTranslated"
        :initial-value="shortLocale"
        @changed="handleLocaleChange"
      />

      <div class="h-fit mt-20 my-auto w-full">
        <!-- Brand logo -->
        <img
          class="w-56 mb-2 mx-auto"
          src="/images/shared/logos/horizontal_blue.svg"
          alt="logo"
        >

        <!-- App section title -->
        <h1 class="text-center text-theme-900 font-bold text-2xl mb-10">
          {{ t('extranet.professional_section') }}
        </h1>

        <div class="max-w-lg mx-auto px-4">
          <router-view />
        </div>
      </div>
    </div>

    <!-- Background with image and brand name -->
    <div
      class="w-0 lg:w-1/2 bg-no-repeat bg-cover bg-center min-h-screen"
      style="background-image: url('/images/extranet/auth/bg.jpg');"
    />
  </section>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import useLocale from '@shared/hooks/locale'
import AppLocaleSelector from '@shared/components/ui/AppLocaleSelector.vue'

const {
  staticLocalesOptionsTranslated,
  shortLocale,
  handleChange: handleLocaleChange,
} = useLocale()

const { t } = useI18n()
</script>

<style scoped lang="scss">
  #auth-locale-selector {
    :deep(.multiselect) {
      @apply rounded-full border-none shadow-lg shadow-theme-500/10;
    }
  }
</style>
