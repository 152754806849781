<template>
  <form
    novalidate
    @submit.prevent="onSubmit"
  >
    <form-global-errors class="mb-5" />

    <form-group
      rules="required"
      name="password"
      type="password"
      :label="false"
      :placeholder="`${$filters.capitalize(t('validation.attributes.new_password'))}`"
      hide-errors
      :form-control-props="{
        autocomplete: 'new-password',
        iconProps: { library: 'local', name: 'lock' },
      }"
    >
      <template #hint>
        <form-password-hint-list
          :password="form.values.password"
        />
      </template>
    </form-group>

    <form-group
      rules="confirmed:@password"
      name="password_confirmation"
      type="password"
      :label="false"
      label-as-placeholder
      error-classes="mt-2"
      :form-control-props="{
        autocomplete: 'new-password',
        iconProps: { library: 'local', name: 'lock' },
      }"
    />

    <app-button
      feature="confirm"
      type="submit"
      width="full"
      :disabled="formSubmitting || invalid"
      :loading="formSubmitting"
      padding="py-3 px-5"
      display-classes="flex"
      class="min-w-fit max-w-8/10 mx-auto mt-8"
    />
  </form>

  <p
    class="font-bold text-center my-2"
  >
    <router-link
      :to="{ name: 'login' }"
      class="app-link"
    >
      {{ t('auth.messages.login') }}
    </router-link>
  </p>

  <p
    class="font-bold text-center"
  >
    {{ t('auth.messages.no_account') }}

    <router-link
      :to="{ name: 'register' }"
      class="app-link"
    >
      {{ t('auth.messages.register') }}
    </router-link>
  </p>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { resetPassword } from '@shared/http/api'
import useForm from '@shared/hooks/form/form'
import AppButton from '@shared/components/ui/AppButton.vue'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'
import FormPasswordHintList from '@shared/components/form/password_hint/FormPasswordHintList.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'

const store = useStore()
const router = useRouter()
const route = useRoute()
const { t } = useI18n()

const uuid = route.params.uuid

const formSubmitting = ref(false)
const formErrors = ref({})

const {
  handleSubmit,
  form,
  invalid,
} = useForm({ additionalErrors: formErrors })

async function onSubmit() {
  const fieldsToSubmit = await handleSubmit()

  if (fieldsToSubmit && !formSubmitting.value) {
    formSubmitting.value = true

    resetPassword(uuid, {
      data: {
        attributes: fieldsToSubmit,
      },
    })
      .then(async () => {
        store.commit('loading/SET_TEXT', t('auth.login.logging_in'))
        store.commit('loading/ENABLE')

        await store.dispatch('auth/getAuthenticatedUser')
        await router.push('/dashboard')
        store.commit(
          'flashes/ADD_FLASH',
          { message: t('auth.password_reset.success'), type: 'success' },
        )
      })
      .catch((e) => {
        if (e.response?.status === 404) {
          store.commit(
            'flashes/ADD_FLASH',
            { message: t('exceptions.404'), type: 'danger' },
          )
        }

        if (e.response?.data?.errors) {
          formErrors.value = e.response.data.errors
        }
      })
      .finally(() => {
        store.commit('loading/RESET')
        formSubmitting.value = false
      })
  }
}
</script>
