<template>
  <form
    novalidate
    @submit.prevent="handleSubmit"
  >
    <form-global-errors />

    <form-group
      rules="required|email"
      name="credential"
      type="email"
      :label="false"
      :placeholder="capitalize(t('validation.attributes.email'))"
      error-classes="mt-2"
      :initial-value="credential"
      :form-control-props="{
        autocomplete: 'username',
        iconProps: { library: 'coolicon', name: 'mail' },
      }"
    />

    <router-link
      v-if="withCredentialVerificationNotReceived"
      :to="{ name: 'credential-verification', query: { credential: values.credential } }"
      class="app-link w-fit text-sm -mt-2 mb-5 ml-0.5 block"
    >
      {{ t('user_credential_verifications.verify.not_received') }}
    </router-link>

    <form-group
      rules="required"
      name="password"
      type="password"
      :label="false"
      label-as-placeholder
      :form-control-props="{
        autocomplete: 'current-password',
        iconProps: { library: 'local', name: 'lock' },
      }"
    />

    <router-link
      v-if="withPasswordForgotten"
      :to="{ name: 'password-forgotten', query: { credential: values.credential } }"
      class="app-link w-fit text-sm -mt-2 mb-5 ml-0.5 block"
    >
      {{ t('auth.messages.reset_password') }}
    </router-link>

    <form-group
      :initial-value="true"
      name="remember"
      type="checkbox"
      flex-direction="row"
      class="text-sm"
    />

    <app-button
      feature="confirm"
      type="submit"
      width="full"
      :disabled="submitting"
      :loading="submitting"
      :label="t('auth.messages.login')"
      padding="py-3 px-5"
      display-classes="flex"
      class="min-w-fit max-w-8/10 mx-auto mt-8"
    />
  </form>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { capitalize } from 'lodash'

import useForm from '@shared/hooks/form/form'
import AppButton from '@shared/components/ui/AppButton.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'

const props = defineProps({
  // JSON API resource used
  // to populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Is the form currently submitting
  // via an ajax request
  submitting: {
    type: Boolean,
    default: false,
  },
  // Additional form errors not generated
  // by VeeValidate rules
  additionalErrors: {
    type: Object,
    default: () => ({}),
  },
  // Display password forgotten link or not
  withPasswordForgotten: {
    type: Boolean,
    default: false,
  },
  // Display credential verification link or not
  withCredentialVerificationNotReceived: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits([
  'submitted',
])

const route = useRoute()

const credential = route.query.credential

const { t } = useI18n()
const {
  handleSubmit,
  values,
} = useForm(props, { emits })

</script>
