<template>
  <p class="font-bold mb-4">
    {{ t('auth.register.success.email_1', { email: form.values.email }) }}
  </p>

  <p class="mb-4">
    {{ t('auth.register.success.email_2') }}
  </p>

  <router-link
    class="app-link text-center block"
    :to="{ name: 'credential-verification', query: { credential: form.values.email } }"
  >
    {{ t('user_credential_verifications.verify.not_received') }}
  </router-link>
</template>

<script setup>
import { inject } from 'vue'
import { useI18n } from 'vue-i18n'

const form = inject('form')

const { t } = useI18n()

defineProps({
  // Registering context object.
  context: {
    type: Object,
    default: null,
  },
})
</script>
